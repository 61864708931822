<template>
<!-- Admin Refunds -->
<v-card v-if="admin">
    <v-card-title class="align-start">
        {{ $t("Refunds") }}
        <v-spacer></v-spacer>
        <v-btn small icon class="me-n3 mt-n1" :to="to">
            <!-- Arrow -->
            <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
            </svg>
        </v-btn>
    </v-card-title>
    <v-card-text class="text-no-wrap text--primary d-flex refunds">
        <div class="d-flex align-center" style="width: 200px">
            <router-link :to="{name: 'admin-refunds-list', params: {tab: 'all'}}">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_2864_129140)">
                        <rect x="10" y="8" width="40" height="40" rx="20" fill="#9155FD" />
                        <path d="M38 22H35V20C35 18.89 34.11 18 33 18H27C25.89 18 25 18.89 25 20V22H22C20.89 22 20 22.89 20 24V35C20 36.11 20.89 37 22 37H38C39.11 37 40 36.11 40 35V24C40 22.89 39.11 22 38 22ZM27 20H33V22H27V20ZM38 35H22V33H38V35ZM38 30H22V24H25V26H27V24H33V26H35V24H38V30Z" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_d_2864_129140" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="5" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2864_129140" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2864_129140" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </router-link>
            <div class="d-flex flex-column ml-2">
                <span class="user-title">{{ $t("All Refunds") }}</span>
                <span class="user-value mt-2">{{ countAll }}</span>
            </div>
        </div>
        <div class="d-flex align-center" style="width: 200px">
            <router-link :to="{name: 'admin-refunds-list', params: {tab: 'refund-requests'}}">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_2864_129140)">
                        <rect x="10" y="8" width="40" height="40" rx="20" fill="#ACACAC" />
                        <path d="M38 22H35V20C35 18.89 34.11 18 33 18H27C25.89 18 25 18.89 25 20V22H22C20.89 22 20 22.89 20 24V35C20 36.11 20.89 37 22 37H38C39.11 37 40 36.11 40 35V24C40 22.89 39.11 22 38 22ZM27 20H33V22H27V20ZM38 35H22V33H38V35ZM38 30H22V24H25V26H27V24H33V26H35V24H38V30Z" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_d_2864_129140" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="5" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2864_129140" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2864_129140" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </router-link>
            <div class="d-flex flex-column ml-2">
                <span class="user-title">{{ $t("Refunds requested") }}</span>
                <span class="user-value mt-2">{{ countRequested }}</span>
            </div>
        </div>
        <div class="d-flex align-center" style="width: 200px">
            <router-link :to="{name: 'admin-refunds-list', params: {tab: 'escalated'}}">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_2864_129140)">
                        <rect x="10" y="8" width="40" height="40" rx="20" fill="#FFB400" />
                        <path d="M38 22H35V20C35 18.89 34.11 18 33 18H27C25.89 18 25 18.89 25 20V22H22C20.89 22 20 22.89 20 24V35C20 36.11 20.89 37 22 37H38C39.11 37 40 36.11 40 35V24C40 22.89 39.11 22 38 22ZM27 20H33V22H27V20ZM38 35H22V33H38V35ZM38 30H22V24H25V26H27V24H33V26H35V24H38V30Z" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_d_2864_129140" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="5" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2864_129140" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2864_129140" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </router-link>
            <div class="d-flex flex-column ml-2">
                <span class="user-title">{{ $t("Escalated to Admin") }}</span>
                <span class="user-value mt-2">{{ countEscalated }}</span>
            </div>
        </div>
    </v-card-text>
</v-card>
<!-- Seller Refunds -->
<statistics-card-vertical v-else :loading="$store.state.order.loading" :color="refunds.color" :icon="refunds.icon" :statistics="countAll" :stat-title="refunds.statTitle" :to="to"></statistics-card-vertical>
</template>

<script>
import {
    mdiPoll
} from '@mdi/js'
import {
    useVModel
} from '@vueuse/core'
import {
    computed,
    onMounted
} from "@vue/composition-api";
import store from '@/store'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
    name: 'Refunds',
    components: {
        StatisticsCardVertical
    },
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {
        emit
    }) {
        const adminData = useVModel(props, 'admin', emit)
        onMounted(() => {
            adminData.value ? store.dispatch('order/getAdminRefundsDash') : store.dispatch('order/getSellerRefundsDash')
        })
        const refunds = {
            statTitle: 'Refunds',
            icon: mdiPoll,
            color: 'error',
        }
        return {
            refunds,
            to: computed(() => {
                return {
                    name: `${adminData.value ? 'admin' : 'seller'}-refunds-list`,
                    params: {
                        tab: 'refund-requests'
                    }
                }
            }),
            countAll: computed(() => store.state.order.countRefunds?.all.toString()),
            countRequested: computed(() => store.state.order.countRefunds?.refundRequests?.toString()),
            countEscalated: computed(() => store.state.order.countRefunds?.escalated?.toString())
        }
    }
}
</script>
