<template>
<statistics-card-vertical :loading="$store.state.order.loading" :color="orders.color" :icon="orders.icon" :statistics="count" :stat-title="orders.statTitle" :to="admin ? {name: 'admin-orders-list'} : {name: 'seller-orders-list'}"></statistics-card-vertical>
</template>

<script>
import {
    mdiPoll
} from '@mdi/js'
import {
    useVModel
} from '@vueuse/core'
import {
    onMounted,
    computed
} from "@vue/composition-api"
import store from '@/store'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
    name: 'Orders',
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    components: {
        StatisticsCardVertical
    },
    setup(props, {
        emit
    }) {
        const adminData = useVModel(props, 'admin', emit)
        onMounted(() => {
            adminData.value ? store.dispatch('order/getAdminOrdersDash') : store.dispatch('order/getSellerOrdersDash')
        })
        const orders = {
            statTitle: 'Orders',
            icon: mdiPoll,
            color: 'secondary'
        }
        return {
            orders,
            count: computed(() => store.state.order.countOrders.toString())
        }
    }
}
</script>
