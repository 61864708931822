<template>
  <v-card :style="`width:${seller ? 47 : 50}%`" min-height="160" :loading="loading" :disabled="loading">
    <v-card-title class="align-start pb-3">
      <v-avatar
        :color="color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          :color="seller ? 'error' : 'white'"
          class="rounded-0"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <v-spacer></v-spacer>

      <v-btn
        small
        icon
        class="me-n3 mt-n1"
        :to="to"
      >
      <!-- Arrow -->
        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A"/>
        </svg>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary">
      <p class="mb-1">
        {{ statTitle }}
      </p>

      <div class="d-flex align-end flex-wrap">
        <span class="text-xl me-1 mt-1">{{ statistics }}</span>
        <span
          class="percentage text-xs"
          :class="checkChange(change) ? 'success--text':'error--text'"
        > {{ change }}</span>
      </div>
      <p class="text-xs text--secondary mb-0">
        {{ subtitle }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    statistics: { default: '-' },
    change: { type: String, default: '' },
    seller: { type: Boolean, default: false },
    to: { type: Object },
    loading: { type: Boolean, default: false }
  },
  setup() {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return {
      checkChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
